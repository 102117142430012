import InputText, { InputTextProps } from '../input/InputText';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { useEffect } from 'react';

export default function ZodFieldInput({
  name,
  ...props
}: Omit<InputTextProps, 'onChange' | 'value'> & { name: string; default?: string }) {
  const [prefixed, value, setValue] = useZodFormFieldSingle(name);

  useEffect(() => {
    if (props.default && !value) {
      setValue(props.default);
    }
  }, []);

  return <InputText {...props} name={prefixed} value={value ?? ''} onChange={(value) => setValue(value || '')} />;
}
